<script>
import EntityForm from "@/mixins/EntityForm";
import WithVisitorLevelPermissions from "@/mixins/WithVisitorLevelPermissions";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";
import _env from "@/plugins/env";

@Component({
  inject: {
    userService: "userService",
    toastService: "toastService",
    visitorLevelService: "visitorLevelService",
    privacyService: "privacyService",
    authService: "authService",
  },
})
export default class UserImport extends mixins(
  EntityForm,
  WithVisitorLevelPermissions
) {
  formId = "import-user-form";
  isSaving = false;

  model = {};
  privacies = [];

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "import",
        type: "submit",
        text: this.translations.labels.userImport_form_importButton,
        leftIcon: "mdi-content-save-outline",
        handle: this.import,
        disabled: () => {
          return this.isSaving;
        },
      },
    ];
  }

  get currentUser() {
    return this.$store.state.authStore.currentUser;
  }

  get helpLink() {
    return this.translations.pageHelp.userImport;
  }

  async import(action, passesValidation) {
    if (!passesValidation) {
      return;
    }
    let confirm = window.confirm(
      this.translations.labels.userImport_import_confirm
    );
    if (confirm) {
      try {
        this.isSaving = true;

        if (this.model.expirationDate?.length < 1)
          this.model.expirationDate = null;
          let obj = {
          currentUser: this.model.currentUser,
          expirationDate: this.model.expirationDate,
          privacies: this.model.privacies.map((x) => x.value),
          syncWithCRM: this.model.syncWithCRM,
          usersFile: this.model.usersFile,
          verifyUsers: this.model.verifyUsers,
          overrideUserData: this.model.overrideUserData,
          changePasswordOnLogin: this.model.changePasswordOnLogin,
          visitorLevels: this.model.visitorLevels,
        };
          const output = await this.userService.import(obj);
        Utility.exportArrayToCsv(output, "user_exported.csv");
      } catch (error) {
        this.errors = this.formatApiErrors(error);
      }
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.users_import;
  }

  get privaciesOptions() {
    return this.privacies.map((p) => {
      return {
        value: p.idPrivacy,
        label: `${p.code} (v${p.version})`,
      };
    });
  }

  get elements() {
    return [
      {
        id: "templateDescription",
        type: "paragraph",
        text: this.translations.labels.userImport_form_templateDescription.replace(
          "/import_users_templates.csv",
          _env("VUE_APP_API_BASE_URL") + "/api/users/import-template-csv"
        ),
        resetColumns: true,
        columns: 2,
      },
      {
        id: "usersFile",
        label: this.translations.labels.userImport_form_usersFile,
        hint: this.translations.labels.userImport_form_usersFile_hint,
        type: "file",
        required: true,
        resetColumns: true,
        columns: 1,
      },
      {
        id: "expirationDate",
        type: "datetime",
        label: this.translations.labels.userImport_form_expirationDate,
        rules: "minDateNow",
      },
      {
        id: "verifyUsers",
        type: "switch",
        label: this.translations.labels.userImport_form_verifyUsers,
      },
      {
        id: "overrideUserData",
        type: "switch",
        label: this.translations.labels.userImport_form_overrideUserData,
      },
      {
        id: "changePasswordOnLogin",
        type: "switch",
        label: this.translations.labels.userImport_form_changePasswordOnLogin,
      },
      {
        id: "syncWithCRM",
        type: "switch",
        label: this.translations.labels.userImport_form_syncWithCRM,
      },
      {
        id: "privacies",
        type: "select",
        multiple: true,
        label: this.translations.labels.userImport_form_privacies,
        hint: this.translations.labels.userImport_form_privacies_hint,
        items: this.privaciesOptions,
        resetColumns: true,
        columns: 2,
        returnObject: true,
      },
      {
        id: "privacyDescription",
        type: "paragraph",
        text:
          this.model.privacies?.length > 0
            ? this.translations.labels.userImport_form_privacyDescription
            : "",
        resetColumns: true,
        columns: 2,
      },
      {
        id: "privacyDetail",
        type: "paragraph",
        text: this.privacyDetail,
        resetColumns: true,
        columns: 2,
      },
      {
        id: "visitorLevels",
        type: "visitor-levels-permissions",
        resetColumns: true,
        transcludeModel: true,
        legend: this.translations.labels.visitor_level,
        label: this.translations.labels.visitor_level,
        levels: this.currentVisitorLevelsOptions,
      },
    ];
  }

  get privacyDetail() {
    let privacyDetailText = this.privacies
      .filter(
        (x) =>
          this.model.privacies.filter((y) => y.value === x.idPrivacy).length > 0
      )
      .map((x) =>
        x.type === "SimpleFlag"
          ? "<b>" + x.localizations[0].label
          : "<b>" +
            x.localizations[0].title +
            "</b>" +
            "\n" +
            x.localizations[0].description
      )
      .join("\n\n");
    return privacyDetailText;
  }

  async created() {
    this.setVisitorLevels(await this.visitorLevelService.enabledLevels());

    this.model = {
      currentUser: this.currentUser.id,
      usersFile: null,
      visitorLevels: this.defaultVisitorLevelsValue(),
      privacies: [],
      verifyUsers: false,
      changePasswordOnLogin: false,
      syncWithCRM: false,
      expirationDate: null,
    };
    this.privacies = await this.privacyService.allEnabled();
  }

  mounted() {
    this.isReady = true;
  }
}
</script>
